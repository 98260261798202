<template>
  <div class="card-box">
    <div :class="{ 'card-item': true, 'card-split': isSplit }">
      <div class="card-top">
        <div class="card-lr">
          <span class="card-title">
            <span class="card-num">{{ idx + 1 }}</span>
            {{ isSplit ? data.type : '销售商品' }}
          </span>
          <el-link v-if="isSplit" type="primary">工艺标准&gt;</el-link>
        </div>
      </div>
      <div class="card-bottom">
        <div class="card-lr">
          <span>品名：{{ data.craft }}</span>
          <span>数量：{{ data.counts }}{{ data.unit }}</span>
        </div>
        <div class="card-lr">
          <span>
            {{ data.texture }}
            {{ data.texture && data.sales_specification ? ',' : '' }}
            {{ data.sales_specification }}
          </span>
          <span v-if="isSplit">每套数量{{ data.counts_unit }}</span>
        </div>
        <div class="card-lr">物料号：{{ data.spwlh }}</div>
      </div>
    </div>
    <div v-if="isSplit && data.children.length" class="card-child">
      <CardItem v-for="(item, index) in data.children" :key="index" :idx="index" :data="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    isSplit: {
      type: Boolean,
      default: true
    },
    idx: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.card-box {
  display: flex;
  // align-items: center;
  & ~ .card-box {
    margin-top: 10px;
  }
  .card-child {
  }
  .card-item {
    display: flex;
    flex-direction: column;
    width: 240px;
    border: 1px solid @colorGrayD;
    border-radius: 5px;
    overflow: hidden;
    .card-top {
      box-sizing: border-box;
      padding: 5px;
      background: @colorBlue;
      .card-title {
        color: @colorWhite;
        .card-num {
          padding: 0 5px;
          color: @colorBlue;
          background: @colorWhite;
          border-radius: 2px;
        }
      }
    }
    .card-bottom {
      box-sizing: border-box;
      flex: 1;
      padding: 10px 5px;
      background: @colorBlue10;
    }
    .card-lr {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      & ~ .card-lr {
        margin-top: 5px;
      }
    }
    &.card-split {
      margin-left: 10px;
      .card-top {
        background: @colorGreen10;
        .card-title {
          color: @colorGreen;
          .card-num {
            color: @colorWhite;
            background: @colorGreen;
          }
        }
      }
      .card-bottom {
        background: @colorWhite;
      }
    }
  }
}
</style>
